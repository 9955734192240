<template>
  <div>
    <v-scale-screen :fullScreen="false" width="1920" height="1080">
      <div class="newPartyScreen">
        <div class="videobg">
          <div class="overlay"></div>
            <video autoplay muted loop objectFit="cover" style="width:100%;height:100%;object-fit: cover">
                <source src="@/assets/img/bg.mp4" type="video/mp4">
            </video>
          </div>
        <!-- 顶部 -->
        <div class="top">
          <div class="top_title">党建引领</div>
          <div class="top_title_people">
            <div class="top">
              <img class="left" src="@/assets/img/tel_phone.png" alt="">
              <div class="right">
                服务终端在线人数
              </div>
            </div>
            <div class="bottom">{{$store.state.onlinePoeple}}</div>
          </div>
        </div>

        <div class="cont">
          <!-- 左中 -->
          <div class="contLeft">
          <div class="nav_left">
            <div class="flex_space flex_align" style="margin-left: 46px;width: 503px;">
              <div class="nav_left_item" v-for="(item,index) in leftList" :key="index" @click="showMode(item,index)">
                <div class="nav_left_img"><img  :src="item.icon" style="width:100%;height:100%"/></div>
                <div class="nav_left_name">{{item.name}}</div>
                <div class="nav_left_num">{{ item.num || 0 }}</div>
              </div>
              <img src='@/assets/img/return.png'  style="height:70px;width:70px;margin-left:8px;z-index:100;display:block;cursor: pointer;" @click="goHome()"/>
            </div>
            <transition name="fade">
								<div class="nav_left_news" v-show="show">
									<div class="news_box flex_space flex_align">
										<div class="flex_center_align">
											<div class="news_rect"></div>
											<div class="news_name">{{content.title}}</div>
										</div>

										<div class="flex_center_align">
											<div>{{content.date}}</div>
											<img src="@/assets/img/rarrow.png" class="news_img" />
										</div>
									</div>
								</div>
							</transition>
          </div>
            <div class="nsLeft">
              <div class="left-center">
                <div
                  class="flex_space flex_center_align"
                  style="margin-left: 24px"
                >
                  <div class="lc_title">党员基本情况</div>
                  <img class="lc_right" src="../assets/img/rarrow.png" @click="showDetail('党员基本情况','base')"/>
                </div>
                <div class="line"></div>
                <div class="panel">
                  <div class="panel_cun">
                    <div class="ageVue">
                      <div class="echartsBg">
                        <img class="age_img" src="../assets/img/popu.png" />
                        <div class="age_num">党员结构</div>
                      </div>
                      <div ref="circle" style="width: 100%;height: 100%;border: 1px solid transparent;-webkit-tap-highlight-color: transparent;user-select: none;"
                      ></div>
                      <div class="echartsLeg">
                        <div class="flex_start">
                          <div
                            class="circle"
                            style="border-color: #4d65b9"
                          ></div>
                          <div>
                            <div class="panel_cun_name">正式党员</div>
                            <div class="panel_cun_num">
                              {{ total.formalMember || 0 }}
                            </div>
                          </div>
                        </div>
                        <div class="flex_start">
                          <div
                            class="circle"
                            style="border-color: #f9b814"
                          ></div>
                          <div>
                            <div class="panel_cun_name">预备党员</div>
                            <div class="panel_cun_num">
                              {{ total.prepareMember || 0 }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="panel_cun_box">
                        <div class="flex_colum flex_box">
                          <div class="flex_start">
                            <div
                              class="flex_colum flex_align"
                              style="
                                height: 60px;
                                justify-content: space-between;
                              "
                            >
                              <div class="panel_cun_name font1">50周年党员</div>
                              <div class="panel_cun_num">
                                <span class="num1">{{ total.developMember || 12 }}</span>
                                <span class="unitFont">人</span>
                              </div>
                            </div>
                          </div>
                          <div class="flex_start" style="margin-top: 37px">
                            <div
                              class="flex_colum flex_align"
                              style="
                                height: 60px;
                                justify-content: space-between;
                              "
                            >
                              <div class="panel_cun_name font1">
                                军队退役党员
                              </div>
                              <div class="panel_cun_num">
                                <span class="num1">{{
                                  total.armyMember || 0
                                }}</span>
                                <span class="unitFont">人</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="flex_colum flex_box">
                          <div class="flex_start">
                            <div
                              class="flex_colum flex_align"
                              style="
                                height: 60px;
                                justify-content: space-between;
                              "
                            >
                              <div class="panel_cun_name font1">优秀党员</div>
                              <div class="panel_cun_num">
                                <span class="num1">{{ total.bedMember || 0 }}</span>
                                <span class="unitFont">人</span>
                              </div>
                            </div>
                          </div>
                          <div class="flex_start" style="margin-top: 37px">
                            <div
                              class="flex_colum flex_align"
                              style="
                                height: 60px;
                                justify-content: space-between;
                              "
                            >
                              <div class="panel_cun_name font1">流动党员</div>
                              <div class="panel_cun_num">
                                <span class="num1">{{
                                  total.flowMember || 0
                                }}</span>
                                <span class="unitFont">人</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="left-center">
                <div
                  class="flex_space flex_center_align"
                  style="margin-left: 24px"
                >
                  <div class="lc_title">党委班子</div>
                  <img class="lc_right" src="../assets/img/rarrow.png"  @click="villageMode('党委班子','cunMembers')"/>
                </div>
                <div class="line"></div>
                <div class="panel">
                  <!-- <div class="panel_top" style="padding-top: 33px">
                    <div class="panel_title">在线课堂</div>
                    <div class="p_line"></div>
                  </div> -->
                  <div style="margin-left:33px;margin-bottom:12px; margin-top:40px">
                   <video id="partyVideo" autoplay muted loop controls @click="openVideoPopup"  :style="{ width: videoWidth + 'px', height: videoHeight + 'px' }">
                      <source src="https://xiaopingfang.baize-agri.cn/prod/api/resources/user/partyBuilding/3.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                    <!-- <video width="334px" height="221px" controls src="https://xiaopingfang.baize-agri.cn/prod/api/resources/user/partyBuilding/3.mp4"></video> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="contCenter">
            <div class="nscenter">
              <!-- 地图 -->
              <div class="mapCss mapCity">
                <div ref="mapBox" style="width: 100%; height: 100%"></div>
              </div>
              <div class="center_bottom">
                <div class="left-center center_bleft">
                  <div class="flex_space flex_center_align">
                    <div class="lc_title">党建专栏</div>
                  </div>
                  <div class="line"></div>
                  <div class="panel" style="padding-top: 12px;">
                    <div class="flex_start">
                        <div style="flex:1;margin-right:100px">
                          <div class="panel_top">
                            <div class="panel_title">党建风貌</div>
                            <div class="p_line"></div>
                          </div>
                          <div class="party_ren">
                                  <swiper :options="swiperOptions">
                                    <swiper-slide v-for="(item, index) in slideList" :key="index">
                                      <img :src="baseURL + item.historyImg" alt="" />
                                    </swiper-slide>
                                    <div class="swiper-pagination" slot="pagination"></div>
                                  </swiper>
                            </div>
                        </div>
                        <div  style="flex:2">
                          <div class="panel_top" style="padding-left: 0">
                            <div class="panel_title">往期专栏</div>
                            <div class="p_line"></div>
                          </div>
                          <div class="party_news">
                              <div class="party_news_item" v-for="(item,index) in djHis" :key="index">
                              <div class="flex_center_align" style="cursor:pointer" @click="showDetail('往期专栏','column',item)">
                                <span class="party_news_dian"></span>
                                {{item.historyName}}
                              </div>
                              <div>{{ item.historyDate }}</div>
                              </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <!-- <div class="nscenter_bottom">
                    <img src="" alt="" />
                    <div class="list">
                      <ul>
                        <li class="item">weqewqeqe</li>
                        <li class="item">weqewqeqe</li>
                        <li class="item">weqewqeqe</li>
                        <li class="item">weqewqeqe</li>
                        <li class="item">weqewqeqe</li>
                      </ul>
                    </div>
                  </div> -->
                </div>
                <!-- <div class="left-center center_nright">
                  <div class="flex_space flex_center_align">
                    <div class="lc_title">监控中心</div>
                    <img class="lc_right" src="./assets/img/rarrow.png" />
                  </div>
                  <div class="line"></div>
                  <div class="panel"></div>
                </div> -->
              </div>
            </div>
          </div>

          <!-- 右 -->
          <div class="contRight">
            <div class="nsright">
              <div class="left-center">
                <div class="flex_space flex_center_align">
                  <div class="lc_title">学历结构</div>
                  <!-- <img class="lc_right" src="./assets/img/rarrow.png" /> -->
                </div>
                <div class="line"></div>
                <div class="panel">
									<div class="panel_cun">
										<div class="nparty_age flex_center_align">
											<div class="echartsBg">
												<img class="age_img" src="@/assets/img/xl.png" />
												<div class="age_num">学历结构</div>
											</div>
                      <div style="width:178px;height:178px;margin: 0 25px 0 25px;">
                        <div class="circle" ref="pagecricle"
                          style="width:100%;height:100%;border: 1px solid transparent;-webkit-tap-highlight-color: transparent;user-select: none;">
                        </div>
                      </div>
											<div class="echartsLeg">
													<div class="flex_start" style="margin-bottom:12px" v-for="(item,index) in partyNums.background"
														:key="index">
														<div class="circle" :style="{borderColor:item.color}"></div>
														<div>
															<div class="panel_cun_name">{{item.name}}</div>
															<div class="panel_cun_num"><span>{{item.value}}</span> |
																<span>{{item.value|formartParth(memberCount.member)}}</span>
															</div>
														</div>
													</div>
											</div>
										</div>
									</div>
                </div>
              </div>
              <div class="left-center" style="margin-top:34px">
                <div class="flex_space flex_center_align">
                  <div class="lc_title">党龄结构</div>
                  <!-- <img class="lc_right" src="./assets/img/rarrow.png" /> -->
                </div>
                <div class="line"></div>
                <div class="panel">
									<div class="panel_cun">
										<div class="nparty_age flex_center_align" style="height:282px">
											<div class="echartsBg">
												<img class="age_img" src="@/assets/img/dl.png" />
												<div class="age_num">党龄结构</div>
											</div>
                      <div style="width:178px;height:178px;margin: 0 25px 0 25px;">
                        <div class="circle" ref="partycricle"
                          style="width:100%;height:100%;border: 1px solid transparent;-webkit-tap-highlight-color: transparent;user-select: none;">
                        </div>
                      </div>
											<div class="echartsLeg">
													<div class="flex_start" style="margin-bottom:12px" v-for="(item,index) in partyNums.branchAge"
														:key="index">
														<div class="circle" :style="{borderColor:item.color}"></div>
														<div>
															<div class="panel_cun_name">{{item.name}}</div>
															<div class="panel_cun_num"><span>{{item.value}}</span> |
																<span>{{item.value|formartParth(memberCount.member)}}</span>
															</div>
														</div>
													</div>
											</div>
										</div>
									</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <partyMode ref="partyDom"></partyMode>
      
			<newMode ref="newMode"></newMode>

      <!-- 党委班子 -->
      <num-mode ref="numMode"></num-mode>

     <div style="width: 100%;height: 100%;background: rgba(0,0,0,0.5);position: fixed;top: 0;left: 0;z-index: 9999!important" v-if="showOne" >
     <div  style="position: absolute;top:250px;left:500px;z-index: 999;height: 600px;width: 50%;margin: 0 auto;" >
      <div style="height: 42px;background: linear-gradient(90deg, #0099FF 0%, #0D1836 100%);">
        <img style="height: 42px;brightness(200%);float: left;" :src="require('@/assets/img/' + 'Vector.svg')"/>
        <!-- <span style="line-height: 42px;color: white;brightness(200%);font-weight: 800;font-size: 25px;font-family:">2023年9月11日会议视频</span> -->
        <span style="line-height: 40px;float: right;color: white;margin-right: 10px;font-size: 25px;cursor: pointer;" @click="close">×</span>
      </div>
      <div style="height: 5px;width: 100%;"></div>
      <div style="height: 500px;width: 100%;padding: 25px 0 25px;background: linear-gradient(90deg, #0D1836 55%, #0D1836 100%);">
        <video style="width: 100%;height: 100%;" autoplay muted loop controls class="video" src="https://xiaopingfang.baize-agri.cn/prod/api/resources/user/partyBuilding/3.mp4"></video>
      </div>
      </div>
    </div>
    </v-scale-screen>
  </div>
</template>
<script>
import { mapOption, barOptions, newlineOption } from '../utils/options.js'
import { mapdata, geoCoordMap, coordinates } from '../utils/mapCenterPoint'
import { vipCarDateList, vipCarList, getIndexStatistics, postLogin, getDangIndex, getHisItems, getAllPartyer, getAllPartys, articleInfoList, getLineClass, getBankList, searchReadNums } from '@/api/api.js'
const mapData = require('../utils/newcun.json')
import * as echarts from 'echarts'
import 'swiper/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import partyMode from '@/components/partyMode.vue'
import newMode from '@/components/newMode.vue'
import numMode from '@/components/numModeNew.vue'
export default {
  components: {
    swiper,
    swiperSlide,
    partyMode,
    newMode,
    numMode
  },
  data() {
    return {
      leftList: [
        {
          name: '民众心声',
          icon: require('@/assets/img/imz.png'),
          type: '3',
          ptype: 'voxPop',
          num: 0
        },
        {
          name: '全民广场',
          icon: require('@/assets/img/iqm.png'),
          type: '2',
          ptype: 'nationalSquare',
          num: 0
        },
        {
          name: '在线课堂',
          icon: require('@/assets/img/isk.png'),
          type: '1',
          ptype: 'readBook',
          num: 0
        },
        {
          name: '道德银行',
          icon: require('@/assets/img/ibank.png'),
          type: '4',
          ptype: 'releaseReq',
          num: 0
        },
        {
          name: '贵客到访',
          icon: require('../assets/img/car.png'),
          type: '5',
          ptype: 'monitor',
          num: 0
        }
      ],
      zpajList: [{}, {}, {}, {}],
      total: {
        familyNum: 0,
        residentNum: 0,
        adminUserNum: 0,
        oftenNum: 0,
        foreignNum: 0,
        flowNum: 0,
        rangerNum: 0,
        impatientNum: 0
      },
      slideList: [],
      baseURL: process.env.VUE_APP_API,

      swiperOptions: {
        autoplay: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets'
        }
      },
      djHis: [],
      partyNums: {},
      rolltimer: '',
      show: false,
      content: {},
      videoWidth: 334,
      videoHeight: 221,
      showOne: false
    }
  },
  filters: {
    formartParth(data, total) {
      let num = (data / total * 100).toFixed(2)
      return num + '%'
    }
  },
  methods: {
    goHome() {
      this.$router.replace('/integrated')
    },
    initMap() {
      var convertData = function(data) {
        var res = []
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name]
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value * 2)
            })
          }
        }
        return res
      }

      echarts.dispose(this.$refs.mapBox)
      var myChart = echarts.init(this.$refs.mapBox)

      var option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0, 153, 255, 0.82)',
          borderColor: '#FFFFCC',
          showDelay: 0,
          hideDelay: 0,
          enterable: true,
          transitionDuration: 0,
          extraCssText: 'z-index:100',
          formatter: function(params, ticket, callback) {
            //根据业务自己拓展要显示的内容
            var res = ''
            var name = params.name
            // console.log(name);
            res = "<span style='color:#fff;'>" + name + '</span>'
            return res
          }
        },
        geo: {
          map: 'xiaopingfang',
          aspectScale: 1, //长宽比
          zoom: 1.15,
          roam: false,
          itemStyle: {
            normal: {
              // areaColor: '#00FFFF', // 地图颜色
              // borderWidth: 8, // 边框的宽度
              shadowColor: '#0099ff', //  阴影颜色
              borderColor: '#00FFFF', // 边框颜色
              shadowBlur: 12, // 阴影的模糊大小
              shadowOffsetX: 0, //阴影水平方向上的偏移距离
              shadowOffsetY: 15, // 阴影垂直方向上的偏移距离
              areaColor: {
                x: 1000,
                y: 1000,
                x2: 1000,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#69c5d8' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#126caf' // 50% 处的颜色
                  }
                ],
                global: true // 缺省为 false
              },
              opacity: 1
            },
            emphasis: {
              areaColor: '#2AB8FF',
              borderWidth: 0,
              color: 'green',
              label: {
                show: false
              }
            }
          },
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: false
            }
          },
          scaleLimit: {
            //所属组件的z分层，z值小的图形会被z值大的图形覆盖
            min: 0.8, //最小的缩放值
            max: 3 //最大的缩放值
          }
        },
        series: [
          {
            type: 'map',
            map: 'xiaopingfang', //使用
            roam: false,
            aspectScale: 1,
            // geoIndex: 0,
            zoom: 1.15,
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: false
              }
            },

            itemStyle: {
              normal: {
                areaColor: '#334fa3', //正常背景颜色
                // borderColor: '#4F8DBB',
                borderColor: '#8cD2',
                borderWidth: 1.5
              },
              emphasis: {
                areaColor: '#2AB8FF', //选中颜色
                borderWidth: 0,
                color: 'green',
                show: false
              }
            },
            scaleLimit: {
              //所属组件的z分层，z值小的图形会被z值大的图形覆盖
              min: 0.8, //最小的缩放值
              max: 3 //最大的缩放值
            }
          },
          {
            name: 'city',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            geoIndex: 0,
            data: convertData(mapdata),
            symbolSize: 5,
            showEffectOn: 'render',
            //涟漪特效相关配置
            rippleEffect: {
              brushType: 'stroke',
              period: 1,
              scale: 2 //动画中波纹的最大缩放比例。
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true,
                color: '#F5F5F5',
                fontWeight: 'bold',
                fontSize: '12'
              }
            },
            itemStyle: {
              normal: {
                color: '#fff',
                shadowColor: '#0098d9', //  阴影颜色
                shadowBlur: 30,
                shadowOffsetX: 0, //阴影水平方向上的偏移距离
                shadowOffsetY: 20, // 阴影垂直方向上的偏移距离
                emphasis: {
                  areaColor: '#2B91B7'
                }
              }
            },
            zlevel: 1
          },
          {
            type: 'lines',
            polyline: true,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.3,
              symbolSize: 6
            },
            lineStyle: {
              width: 1.5,
              color: '#7ee0ff',
              opacity: 0.5
            },
            progressiveThreshold: 500,
            progressive: 200,
            data: coordinates,
            zlevel: 10
          }
        ]
      }

      echarts.registerMap('xiaopingfang', mapData)
      // let map = echarts.init(this.$refs.mapBox, null, {
      //   renderer: 'svg',
      // })
      // map.clear()
      myChart.setOption(option)
    },
    initChart() {
      this.initMap()
      // 地图绘制
      echarts.registerMap('xiaopingfang', mapData)
      // let map = echarts.init(this.$refs.mapBox, null, { renderer: 'svg' })
      // map.clear()
      // map.setOption(mapOption)

      barOptions.xAxis.data = ['泰达砖厂', '小平房铁矿', '万昆矿业', '永山车队', '秀源果业']
      barOptions.yAxis.axisLine.lineStyle.color = '#fff'
      // barOptions.yAxis.axisLabel.textStyle.fontSize = 7
      barOptions.grid.x = 40
      barOptions.grid.bottom = 20
      barOptions.yAxis.name = '万元'
      barOptions.series[0] = {
        type: 'bar',
        showBackground: false,
        barMaxWidth: 16, // 每一个都要设置
        barMinWidth: 5, // 每一个都要设置
        itemStyle: {
          barBorderRadius: [6, 6, 6, 6],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 0, color: '#FFFFFF' }, { offset: 1, color: '#0063B8' }]),
          emphasis: {
            label: {
              show: true,
              position: 'top',
              color: '#fff'
            }
          }
        },
        data: ['2000', '3000', '8000', '150', '1500']
      }
      let chart = echarts.init(this.$refs.industry, null, { renderer: 'svg' })
      chart.clear()
      chart.setOption(barOptions)

      newlineOption.title.text = '天秀山'

      let linenew = echarts.init(this.$refs.saleLine, null, {
        renderer: 'svg'
      })
      linenew.clear()
      linenew.setOption(newlineOption)

      let linen = echarts.init(this.$refs.people, null, { renderer: 'svg' })
      linen.clear()
      linen.setOption(newlineOption)
    },
    drowCircle(data) {
      let circleOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#4D65B9', '#F9B814'],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'circle',
          right: '0',
          top: '10%',
          textStyle: {
            color: '#fff'
          },
          data: ['正式党员', '预备党员']
        },
        series: [
          {
            name: '党员结构',
            type: 'pie',
            radius: ['75%', '90%'],
            center: ['38%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: [{ value: data.formalMember, name: '正式党员' }, { value: data.prepareMember, name: '预备党员' }]
          }
        ]
      }
      let cricle = echarts.init(this.$refs.circle, null, { renderer: 'svg' })
      cricle.clear()
      cricle.setOption(circleOption)
    },
    getgrids(value) {
      // getDangIndex
      getDangIndex({ villageId: value }).then(res => {
        console.log(res)
        if (res.code == 0) {
          this.total = res.model.memberCount
          this.$nextTick(() => {
            this.drowCircle(res.model.memberCount)
          })
        }
      })
      // getIndexStatistics().then(res => {
      //   if (res.code == 0) {
      //     this.total = res.items[0]
      //     this.$nextTick(() => {
      //       this.drowCircle(res.items[0])
      //     })
      //   }
      // })
    },
    //登录
    submitForm(formName) {
      let form = {
        username: 'xiaopingfang',
        password: '123456'
      }
      postLogin(form)
        .then(res => {
          if (res.code == '0') {
            // sessionStorage.clear()
            this.resetSetItem('userInfo', JSON.stringify(res.model))
            sessionStorage.setItem('token', res.model.tokenValue)
            this.searchDetails(res.model.regionId)
            this.getItems(res.model.regionId)
            this.getgrids(res.model.regionId)
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    searchDetails(regionId) {
      getDangIndex({
        villageId: regionId
      }).then(res => {
        if (res.code == 0) {
          let arr = ['#4DB9A5', '#5F8CFF', '#6B1F9C', '#4D65B9', '#F9B814', '#4D65B9']
          if (res.model.background) {
            res.model.background.map((item, index) => {
              item.color = arr[index]
            })
          }
          if (res.model.branchAge) {
            res.model.branchAge.map((item, index) => {
              item.color = arr[index]
            })
          }
          this.partyNums = res.model
          this.memberCount = res.model?.memberCount || {}
          this.$nextTick(() => {
            this.drowFunction(res.model)
          })
        }
      })
    },
    //绘制党建部分环形图
    drowFunction(data) {
      let edLegend = []
      // 学历结构
      let edOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#4DB9A5', '#5F8CFF', '#6B1F9C', '#4D65B9', '#F9B814', '#4D65B9'],
        legend: {
          show: false
        },
        series: [
          {
            name: '学历结构',
            type: 'pie',
            radius: ['85%', '100%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: data.background
          }
        ]
      }
      let edstr = echarts.init(this.$refs.pagecricle, null, {
        renderer: 'svg'
      })
      edstr.clear()
      edstr.setOption(edOption)

      //  let edLegend = []
      // 学历结构
      let partyOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#4DB9A5', '#5F8CFF', '#6B1F9C', '#4D65B9', '#F9B814', '#4D65B9'],
        legend: {
          show: false
        },
        series: [
          {
            name: '党龄统计',
            type: 'pie',
            radius: ['85%', '100%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: data.branchAge
          }
        ]
      }
      let party = echarts.init(this.$refs.partycricle, null, {
        renderer: 'svg'
      })
      party.clear()
      party.setOption(partyOption)
    },
    //查询党建列表
    getItems(regionId) {
      getHisItems({ villageId: regionId, historyType: 1, page: 1, rows: 10000, descFlag: 1 }).then(res => {
        if (res.code == '0') {
          if (res.items.length > 0) {
            let arr = []
            res.items.map(item => {
              if (item?.historyImg) arr.push(item)
              if (item.historyDate) item.historyDate = this.$moment(item.historyDate).format('YYYY-MM-DD')
            })
            this.slideList = arr.length > 12 ? arr.slice(0, 12) : arr
          }
          if (this.slideList.length > 1) {
            this.swiperOptions.autoplay = {
              delay: 3000,
              loop: true,
              disableOnInteraction: true
            }
          }
          this.djHis = res.items
        }
      })
    },
    showDetail(name, type, item) {
      if (type == 'base') {
        //党员基本情况
        getAllPartyer({ memberType: 5 }).then(res => {
          if (res.code == '0') {
            if (res.model.length) {
              res.model.forEach(item => {
                if (item.memberList.length) {
                  item.memberList.map(itemData => {
                    if (itemData.avatarUrl) itemData.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + itemData.avatarUrl
                    if (itemData.memberName) itemData.nickName = itemData.memberName
                  })
                }
              })
              let onbj = {
                morePeoples: res.model,
                name: name,
                showType: type
              }
              this.$refs.partyDom.show = true
              this.$refs.partyDom.initData(onbj)
            }
          }
        })
      } else if (type == 'partyclass') {
        let form = JSON.parse(sessionStorage.getItem('userInfo'))
        //党委班子
        getAllPartys({ villageId: form.regionId }).then(res => {
          if (res.code == '0') {
            if (res.model.length) {
              let onbj = {
                morePeoples: res.model,
                name: name,
                showType: type
              }
              this.$refs.partyDom.show = true
              this.$refs.partyDom.initData(onbj)
            }
          }
        })
      } else if (type == 'column') {
        console.log(item)

        let onbj = {
          itemData: item,
          name: item.historyName,
          showType: type
        }
        this.$refs.partyDom.show = true
        this.$refs.partyDom.initData(onbj)
      }
    },
    showMessage(content) {
      this.content = content
      this.show = true
      if (content) {
        let item = this.leftList.filter(item => {
          return item.type == content.type
        })[0]
        let index = this.leftList.findIndex(item => item.type == content.type)

        setTimeout(() => {
          item.num = item.num + 1
          this.$set(this.leftList, index, item)
          this.content = {}
          this.show = false
        }, 20000) // 3秒后隐藏消息
      }
    },
    searchNews() {
      this.nerwstimer = setInterval(() => {
        this.getReadnums()
      }, 10000) // 滚动速度
    },
    showMode(item, index) {
      console.log('111')
      if (['3', '2'].includes(item.type)) {
        this.searchArticles(item.type, item, index)
      } else if (item.type == '4') {
        // 道德银行
        this.searchBnakList(item, index)
      } else if (item.type == '1') {
        getLineClass().then(res => {
          if (res.code == '0') {
            if (!res.model.length) {
              this.$message.error('暂无数据')
              return
            }
            let newData = {
              form: item,
              list: res.model
            }
            this.$refs.newMode.show = true
            this.$refs.newMode.initData(newData)
          }
        })
      } else if (item.type == '5') {
        this.vipCarList(item, index)
      }

      this.clearRead(item.ptype)
    },
    vipCarList(item, index) {
      vipCarDateList().then(res => {
        if (res.code == '0') {
          console.log('res', res)
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchArticles(type, data, index) {
      let params = {
        townId: 6281,
        page: 1,
        rows: 100000,
        articleType: 1,
        articleSubtype: type
      }
      articleInfoList(params).then(res => {
        if (res.code == '0') {
          this.articleList = res.items
          if (!res.items.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: data,
            articleList: res.items
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchBnakList(item, index) {
      getBankList().then(res => {
        if (res.code == '0') {
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    // 视频弹窗
    openVideoPopup() {
      this.showOne = true
    },

    // 关闭预览
    close() {
      this.showOne = false
    },
    villageMode(name, type) {
      let item = { type: type, name: name, showType: '', regionId: this.baseForm.regionId }
      if (['cunMembers', 'griders', 'rangerNum', 'forestPreNum', 'impatientNum'].includes(type)) {
        item.showType = 'peopleImg'
      } else if (['residentNum', 'oftenNum', 'foreignNum', 'flowNum'].includes(type)) {
        item.showType = 'description'
      } else if (['familyNum'].includes(type)) {
        item.showType = 'tableList'
      }
      this.$refs.numMode.initData(item)
    },
    getReadnums() {
      searchReadNums().then(res => {
        if (res.code == '0') {
          this.leftList.map(i => {
            if (i.ptype == 'voxPop') i.num = res.model.voxPopNum
            if (i.ptype == 'nationalSquare') i.num = res.model.nationalSquareNum
            if (i.ptype == 'readBook') i.num = res.model.onlineReadNum
            if (i.ptype == 'releaseReq') i.num = res.model.releaseReqNum
            //贵客到访
            if (i.ptype == 'monitor') i.num = res.model.monitorNum
          })
          //终端在线人数
          this.$store.state.onlinePoeple = res.model.onlineNums
        }
      })
    }
  },
  mounted() {
    console.log(process.env.VUE_APP_API)

    this.submitForm()
    this.$nextTick(() => {
      this.initChart()
    })
    //消息推送
    this.searchNews()
    this.getReadnums()
    this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
  },
  destroyed() {
    if (this.nerwstimer) window.clearInterval(this.nerwstimer)
  }
}
</script>
<style lang="scss" scoped>
@font-face {
  font-family: 'JDLangZhengTi';
  src: url('../assets/fonts/JDLangZhengTi.ttf') format('truetype');
}
@font-face {
  font-family: 'ShiShangZhongHeiJianTi';
  src: url('../assets/fonts/ShiShangZhongHeiJianTi.ttf') format('truetype');
}

// 公共
.lc_title {
  font-family: JDLangZhengTi;
  font-size: 30px;
  font-weight: 400;
  line-height: 32px;
  color: #fff;
  margin-left: 21px;
  position: relative;
}

.lc_title::before {
  content: '';
  display: inline-block;
  width: 7px;
  height: 32px;
  background: #0099ff;
  box-shadow: 0px 0px 13px 1px #0099ff;
  position: absolute;
  top: 0;
  left: -21px;
}
.lc_right {
  margin-right: 44px;
}
.panel {
  width: 100%;
  background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0) 100%);
  overflow: hidden;
  clear: both;
  .panel_top {
    padding: 27px 0 0 31px;
  }
  .panel_title {
    font-family: JDLangZhengTi;
    font-size: 20px;
    font-weight: 400;
    color: #0099ff;
    margin-bottom: 11px;
  }
  .p_line {
    width: 319px;
    height: 2px;
    background: linear-gradient(90deg, #dad6da 0%, rgba(7, 128, 174, 0) 100%);
  }
}
.line {
  background: linear-gradient(90deg, #0780ae 0%, rgba(7, 128, 174, 0) 100%);
  height: 2px;
  margin-top: 21px;
}
.newPartyScreen {
  width: 100%;
  height: 100%;
  // background-image: url('../assets/img/bg1.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  color: #fff;
  .videobg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(41, 6, 22, 0.8), rgba(8, 16, 64, 0.8));
  }
  .top_title {
    font-family: JDLangZhengTi;
    font-size: 34px;
    font-weight: 400;
    text-align: center;
    color: #c4e6fe;
    text-shadow: #0099ff 0px 0px 15px;
    width: 1471.5px;
    height: 83px;
    line-height: 83px;
    margin: 0 auto;
    background-image: url('../assets/img/itop.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .top_title_people {
    width: 253px;
    height: 47px;
    margin: -10px auto 0;
    background-image: url('../assets/img/itop2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-shadow: #0099ff 0px 0px 15px;

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 13px;
      margin-left: 25px;
      margin-top: 5px;

      .left {
        height: 40px;
        width: 40px;
      }

      .right {
        height: 13px;
        font-family: Microsoft YaHei;
        font-size: 10px;
        font-weight: 400;
        line-height: 13px;
        color: rgba(255, 255, 255, 1);
      }
    }

    .bottom {
      height: 22px;
      line-height: 22px;
      font-family: ShiShangZhongHeiJianTi;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(255, 255, 255, 1);
      margin-right: 35px;
      margin-top: 5px;
      // box-shadow: 0px 0px 10px 1px rgba(0, 153, 255, 1);
    }
  }
  .cont {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: -84px;
    position: relative;
  }
  .nav_left {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    position: absolute;
    left: 0;
    top: 0;
    &_item {
      width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
    }
    &_img {
      width: 59px;
      height: 59px;
      border-radius: 10px;
      display: block;
      margin: 0 auto;
    }
    &_name {
      font-family: JDLangZhengTi;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
    }
    &_num {
      position: absolute;
      top: -6px;
      right: 8px;
      z-index: 99;
      background: #ff6b00;
      border-radius: 15px;
      text-align: center;
      font-family: JDLangZhengTi;
      font-size: 8px;
      font-weight: 400;
      min-width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 4px;
    }
  }
  .nav_left_news {
    margin-top: 12px;
    height: 34px;
    .news_box {
      width: 400px;
      height: 100%;
      background: linear-gradient(90deg, rgba(196, 153, 88, 0.33) 0%, rgba(13, 24, 54, 0) 100%);
      font-family: Microsoft YaHei;
      font-size: 12px;
      font-weight: 400;
      .news_rect {
        width: 6px;
        height: 6px;
        box-shadow: 0px 0px 13px 1px #ffffff;
        background: #ffffff;
        margin: 0 18px 0 24px;
      }
      .news_name {
        width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .news_img {
        width: 28px;
        height: 28px;
        margin-left: 6px;
      }
    }
  }
  .nav_right {
    position: absolute;
    right: 81px;
    top: 0;
    width: 477px;
  }
  .nsLeft {
    width: 400px;
    margin-top: 147px;

    .left-center .panel_cun {
      width: 400px;
      height: 440px;
      padding: 0 0 0 64px;
      .panel_cun_box {
        padding-top: 40px;
        // height: 117px;
        width: 233px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 60px;
        .flex_box {
          justify-content: space-between;
          align-items: center;
          .font1 {
            // width: 63px;
            height: 16px;
            font-family: Microsoft YaHei;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            color: #b9b9b9;
          }
          .num1 {
            font-family: ShiShangZhongHeiJianTi;
            font-size: 30px;
            font-weight: 400;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: left;
            color: #ffffff;
          }
          .unitFont {
            color: #0099ff !important;
            height: 16px;
            width: 12px;
          }
        }
      }
      .circle {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        border: 5px solid #ffffff;
      }
      .panel_cun_name {
        font-size: 12px;
        line-height: 15.96px;
        margin-left: 6px;
      }
      .panel_cun_num {
        font-family: ShiShangZhongHeiJianTi;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-left: 6px;
      }
      .ageVue {
        width: 285px;
        height: 195px;
        position: relative;
        // overflow: hidden;
        margin-top: 40px;
        .echartsBg {
          position: absolute;
          width: 78%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .age_img {
          width: 42px;
          height: 42px;
        }
        .age_num {
          font-family: ShiShangZhongHeiJianTi;
          font-size: 32px;
          font-weight: 400;
          line-height: 38px;
          // letter-spacing: 0em;
          // text-align: center;
          text-shadow: 0px 1px 10px #0099ff;
        }
        .echartsLeg {
          position: absolute;
          right: -30px;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 100%;
          .panel_cun_name {
            font-size: 14px;
            line-height: 18.48px;
          }
          .panel_cun_num {
            font-family: ShiShangZhongHeiJianTi;
            font-size: 22px;
            font-weight: 400;
            line-height: 26px;
            text-shadow: 0px 1px 10px #0099ff;
            margin-top: 4px;
          }
        }
      }
    }
    .line_box {
      width: 360px;
      // height: 369px;
      // padding: 27px 25px 35px 38px;
      padding: 20px;
    }
  }
  .nscenter {
    width: 1103px;
    margin-top: 147px;
    .mapCity {
      width: 710px;
      margin: 0 auto;
      height: 538px;
    }
    .center_bottom {
      display: flex;
      align-items: flex-start;
      .center_bleft {
        flex: 1.5;
        margin-right: 27px;
        margin-left: 3px;
      }
      .center_nright {
        flex: 1;
        margin-right: 24px;
      }

      .nscenter_bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 340px;
          height: 262px;
          margin: 16px;
          background-color: #0099ff;
        }
        .list {
          padding-left: 28px;
          height: 262px;

          .item {
            padding: 7px 0;
            height: 25px;
            font-family: Source Han Sans CN;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
            color: #ffffff;
          }
        }
      }
    }
  }
  .nsright {
    width: 400px;
    margin-top: 97px;
    .right_item {
      width: 354px;
      font-family: Microsoft YaHei;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
      margin: 14px 0 0 37px;
      &_box {
        flex: 1;
        margin-bottom: 21px;
        .item_title {
          margin-bottom: 8px;
        }
        .muNum {
          font-family: ShiShangZhongHeiJianTi;
          font-size: 30px;
          font-weight: 400;
          line-height: 36px;
          text-shadow: 0px 1px 10px #0099ff;
          margin-right: 6px;
        }
        .mu {
          color: #0099ff;
        }
      }
    }
  }
  .party_ren {
    margin: 20px 0 53px 32px;
    .swiper-container {
      width: 247px;
      height: 156px;
      margin-left: 0;
      .swiper-slide {
        width: 100%;
        height: 100%;
        flex-direction: column !important;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .party_news {
    width: 576px;
    overflow-y: scroll;
    height: 178px;
    margin-top: 23px;
    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 13px;
      font-family: SourceHanSansCN;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
    }
    &_dian {
      width: 2px;
      height: 2px;
      display: inline-block;
      background-color: #fff;
      border-radius: 50%;
      margin: 0 5px;
    }
  }
  .nparty_age {
    height: 520px;
    position: relative;
    .echartsBg {
      position: absolute;
      width: 56%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .age_img {
        width: 42px;
        height: 42px;
      }
      .age_num {
        font-family: ShiShangZhongHeiJianTi;
        font-size: 32px;
        font-weight: 400;
        line-height: 38px;
        text-shadow: 0px 1px 10px #0099ff;
      }
    }
    .circle {
      margin-right: 13px;
    }
    .panel_cun_name {
      font-size: 12px;
      line-height: 15.96px;
      margin-left: 6px;
      margin-bottom: 6px;
    }
    .panel_cun_num {
      font-family: ShiShangZhongHeiJianTi;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      margin-left: 6px;
    }
  }
}
.party_news::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.party_news::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #0d53b7;
  -webkit-box-shadow: inset 0 0 5px #0d53b7;
  background: #535353;
}
.party_news::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: transparent;
}

.nav_right .el-table th.el-table__cell {
  background-color: transparent !important;
  color: #ceb77b !important;
  height: 0.15rem !important;
}
.nav_right .el-table .el-table__cell {
  padding: 0 !important;
  height: 0.15rem !important;
  font-size: 0.12rem;
}
.nav_right .el-table thead {
  color: #fff !important;
  font-size: 0.12rem !important;
}
.nav_right > .el-table,
.el-table__expanded-cell {
  background-color: transparent !important;
}
.nav_right .el-table tr {
  color: #fff !important;
  background: transparent !important;
}

/* 用来设置当前页面element全局table 选中某行时的背景色*/
.nav_right .el-table__body tr.current-row > td {
  background-color: inherit !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
/* 用来设置当前页面element全局table 鼠标移入某行时的背景色*/
.nav_right .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: inherit !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
.nav_right .el-table__body-wrapper {
  overflow: hidden !important;
}
.nav_right .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none !important;
}
.nav_right .el-table__body,
.el-table__footer,
.el-table__header {
  width: 100% !important;
}
.nav_right .el-table .cell {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.nav_right .el-table::before {
  height: 0 !important;
}
</style>