<template>
	<div class="newmode" v-if="show">
		<div class="cun_inner">
			<div class="modeTop">
				<div class="flex_center_align">
					<img class="modeTop_img" :src="require('@/assets/img/'+ paramsData.type +'.png')"
						v-if="paramsData.type" />
					<div class="modeTop_title">{{paramsData.name}}</div>
				</div>
				<div class="modeTop_close" @click="close">
					<img class="mode_img" src="@/assets/img/close1.png" />
				</div>
			</div>
			<div class="mode_content">
				<!-- 民众心声、全民广场 -->
				<div class="mode_content_box" v-if="paramsData.showType=='base'">
					<!-- 党员基本信息表格 -->
          <div >
						<!-- 党员基本情况 -->
						<div style="width: 80%;margin: 0 auto;">
							<div class="se_title new_cunTitle">党员基本情况</div>
							<table border="" class="table tc" style="margin-top: 10px">
								<tbody>
									<tr>
										<th rowspan="2">党员总数</th>
										<th rowspan="2">126</th>
										<th>男</th>
										<th>89</th>
										<th>69.53%</th>
									</tr>
									<tr>
										<th>女</th>
										<th>37</th>
										<th>30.47%</th>
									</tr>
									<tr>
										<th colspan="2">30周岁以下</th>
										<th colspan="2">10</th>
										<th>7.8%</th>
									</tr>
									<tr>
										<th colspan="2">31-44周岁</th>
										<th colspan="2">41</th>
										<th>32%</th>
									</tr>
									<tr>
										<th colspan="2">45-59周岁</th>
										<th colspan="2">30</th>
										<th>21.9%</th>
									</tr>
									<tr>
										<th colspan="2">60周岁以上</th>
										<th colspan="2">45</th>
										<th>38.3%</th>
									</tr>
								</tbody>
							</table>
						</div>
						<div style="width: 80%;margin: 0 auto;">
							<div class="se_title new_cunTitle">50周年党员</div>
							<table border="" class="table tc" style="margin-top: 10px">
								<thead>
									<tr>
										<th>姓名</th>
										<th>入党日期</th>
										<th>所在党支部</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item,index) in partyList" :key="index">
										<th>{{item.name}}</th>
										<th>{{item.date}}</th>
										<th>{{item.memeber}}</th>
									</tr>
								</tbody>
							</table>
						</div>
						<template>
							<div v-for="(item,index) in morePeoples" :key="index">
								<div class="se_title new_cunTitle">{{ item.branchName }}</div>
								<div class="peoples_box" style="justify-content: space-evenly">
									<div class="people_item" v-for="(item,index) in item.memberList" :key="index"
										@click="showDetails(item)">
										<div class="peoples_img" :class="paramsData.type=='griders'?'':'item_width'">
											<img style="width:100%;height:100%" :src="item.avatarUrl"
												v-if="item.avatarUrl" />
											<img style="width:100%;height:100%" src="@/assets/img/user.png" v-else />
										</div>
										<div class="peoples_name">{{item.memberName}}</div>
									</div>
								</div>
							</div>
						</template>
            </div>
				</div>
        <div v-if="paramsData.showType=='partyclass'">
            <!-- 党组织架构 -->
            <div class="flex_center_align" style="width: 80%;margin: 0 auto;">
              <div class="party_left">
                组织架构
              </div>
              <!-- {{peopleList}} -->
              <div class="party_right">
                <div class="party_right_item" v-for="(item,index) in peopleList" :key="index">
                  <div class="party_right_item_center" @click="partyfunction(item)">
                    <div class="party_right_title">{{item.branchName}}</div>
                    <div class="party_right_title" style="margin:0">{{item.memberNums||0}}人</div>
                    <div class="party_right_shu">支部书记</div>
                    <div class="party_right_shu" style="margin:0">{{item.secretaryName}}</div>
                  </div>
                </div>
              </div>

            </div>
        </div>
        <div class="mode_content_box" v-if="paramsData.showType=='column'">
          <div class="dmode_box">
              <div class="dmode_cover" v-if="itemData.historyImg">
                <img class="mode_img" :src="baseUrl+itemData.historyImg" />
              </div>
              <div class="mode_title">{{paramsData.name}}</div>

              <div class="mode_article" v-html="itemData.historyText" v-if="itemData.historyText"></div>
              <div class="mode_avatar_box dmode_box">
                  <div class="mode_date" style="margin-left:4px">{{itemData.createDate}}</div>
                </div>
          </div>
          </div>

			</div>

		</div>


		<div class="newmode detailMode" v-if="detailShow">
			<div class="cun_inner">
				<div class="modeTop">
					<div class="flex_center_align">
						<div style="margin-left:16px">{{paramsData.name}}</div>
					</div>
					<div class="modeTop_close" @click="close('detail')">
						<img class="mode_img" src="@/assets/img/close1.png" />
					</div>
				</div>
				<div class="mode_content">
					<!-- 民众心声、全民广场 -->
					<div class="dmode_box" v-if="paramsData.showType=='partyclass'">
						<div class="table_box">
							<el-table stripe :data="partyData" height=" 500">
								<el-table-column label="姓名" align="center" prop="memberName" />
								<el-table-column label="性别" align="center" prop="memberSex" :formatter="sexFormat" />
								<el-table-column label="职位" align="center" prop="positionName" width="120" />
								<el-table-column label="简介" align="center" prop="remarks" />
							</el-table>
						</div>
					</div>
					<!-- 道德银行 -->
					<div class="dmode_box" v-if="paramsData.showType=='base'">
						<div class="main_title">{{ itemData.nickName||itemData.fullName }}简介</div>
						<div class="details_box">
							<div class="people_item" style="width: 14.5%;margin: 0 auto;">
								<img style="width:100%;height:100%" :src="itemData.avatarUrl"
									v-if="itemData.avatarUrl" />
								<img style="width:100%;height:100%" src="@/assets/img/user.png" v-else />
								<div class="peoples_name">{{itemData.nickName||itemData.fullName}}</div>
							</div>
							<div class="people_inc">
								{{itemData.remarks}}
							</div>
							<div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>



	</div>
</template>

<script>
import { getArticleInfo, getAllPartyMembers } from '@/api/api.js'
import { Loading } from 'element-ui'
// import { url } from 'inspector'
export default {
  data() {
    return {
      baseURL: process.env.VUE_APP_API,
      show: false,
      paramsData: {},
      queryParams: {
        page: 1,
        rows: 10,
        loadMoreText: '加载中...',
        showLoadMore: false,
        total: '',
        newTotal: '',
        nodata: false
      },
      articleList: [],
      detailShow: false,
      itemData: {},
      baseUrl: '/prod/api',
      baseUrl1: '/prod/wx', //小程序端发布
      banklist: [],
      morePeoples: [],
      partyList: [
        {
          name: '潘爽',
          date: '1963.06.01',
          memeber: '愁水坑党支部'
        },
        {
          name: '潘合',
          date: '1968.01.01',
          memeber: '愁水坑党支部'
        },
        {
          name: '刘荣',
          date: '1968.07.01',
          memeber: '愁水坑党支部'
        },
        {
          name: '李凤珍',
          date: '1968.07.01',
          memeber: '愁水坑党支部'
        },
        {
          name: '邹吉福',
          date: '1968.11.01',
          memeber: '河北党支部'
        },
        {
          name: '邹吉全',
          date: '1968.11.01',
          memeber: '河北党支部'
        },
        {
          name: '梁玉祥',
          date: '1969.03.01',
          memeber: '河北党支部'
        },
        {
          name: '李开友',
          date: '1969.05.01',
          memeber: '河南党支部'
        },
        {
          name: '马凤英',
          date: '1969.07.01',
          memeber: '河南党支部'
        },
        {
          name: '孙玉彩',
          date: '1970.03.01',
          memeber: '石灰窑子党支部'
        },
        {
          name: '王  林',
          date: '1972.12.07',
          memeber: '石灰窑子党支部'
        },
        {
          name: '李殿生',
          date: '1973.04.10',
          memeber: '石灰窑子党支部'
        }
      ],
      peopleList: [],
      partyData: []
    }
  },
  methods: {
    sexFormat(row, column) {
      if (row.memberSex == '1') {
        return '男'
      } else {
        return '女'
      }
    },
    initData(data) {
      console.info('zujian', data)
      this.loading = true

      this.paramsData = data

      if (data?.showType == 'base') {
        this.morePeoples = data.morePeoples
      }
      if (data?.showType == 'partyclass') {
        this.peopleList = data.morePeoples
      }
      if (data?.showType == 'column') {
        this.itemData = data.itemData
      }
      this.show = true
    },
    close(type) {
      if (type == 'detail') {
        this.detailShow = false
        this.itemData = {}
      } else {
        // else if (type == 'party') {
        //   // this.partyShow = false
        //   this.partyData = []
        // }
        this.show = false
        //   this.numsForm = []
        this.peopleList = []
        this.paramsData = {}
        this.articleList = []
        this.morePeoples = []
        this.itemData = {}
      }
    },
    // 查看详情
    toDetail(id) {
      getArticleInfo(id).then(res => {
        if (res.code == '0') {
          this.itemData = res.model
          this.$nextTick(() => {
            this.detailShow = true
          })
        }
      })
    },
    seeDetail(item) {
      this.itemData = item
      this.detailShow = true
    },
    partyfunction(item) {
      if (!item.memberNums || item.memberNums == 0) {
        return
      }
      getAllPartyMembers({
        branchId: item.id
      }).then(res => {
        if (res.code == '0') {
          this.partyData = res.model
          this.detailShow = true
        }
      })
    },
    //人员详情
    showDetails(item) {
      if (!item.remarks) {
        return
      }
      this.itemShow = true
      this.itemData = item
    }
  }
}
</script>

<style lang="scss" scoped>
.newmode {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  color: #fff;

  .mode_img {
    width: 100%;
    height: 100%;
  }

  .cun_inner {
    width: 1139px;
    height: 734px;
    position: absolute;
    left: 391px;
    top: 173px;
    z-index: 1000;
    padding: 20px 10px;
    border-radius: 10px;
    animation-name: small-to-big;
    animation-duration: 1s;
    animation-fill-mode: forwards;

    @keyframes small-to-big {
      from {
        transform: scale(0);
      }

      to {
        transform: scale(1);
      }
    }

    .modeTop {
      width: 100%;
      height: 42px;
      background-image: url('../assets/img/titlebg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: JDLangZhengTi;
      font-size: 26px;
      font-weight: 400;
      margin-bottom: 7px;
      &_title {
        margin-left: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 800px;
      }

      &_img {
        margin-left: 14px;
      }

      &_close {
        width: 45px;
        height: 45px;
        margin-right: 8px;
      }
    }

    .mode_content {
      width: 100%;
      height: 685px;
      overflow-y: scroll;
      background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);

      &_box {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 26px 0 26px 26px;
      }

      &_item {
        width: 254px;
        height: 219px;
        border-radius: 10px;
        overflow: hidden;
        background: #00000026;
        margin: 0 20px 20px 0;
      }

      &_img {
        width: 254px;
        height: 149px;
        border-radius: 10px 10px 0px 0px;
        width: 100%;
        height: 149px;
        border-radius: 10px 10px 0 0;
      }

      &_title {
        font-family: SourceHanSansCN;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        margin: 12px 10px 8px;
        white-space: nowrap;
        /* 不换行 */
        overflow: hidden;
        /* 溢出部分隐藏 */
        text-overflow: ellipsis;
        /* 超出部分显示省略号 */
      }

      .mode_avatar_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;

        .mode_avatar {
          width: 14px;
          height: 14px;
          border-radius: 2px;
          overflow: hidden;
          margin: 0 4px 0 10px;
        }

        .mode_date {
          margin-right: 10px;
        }
      }
    }

    .mode_bank_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 410px;
      height: 45px;
      background: linear-gradient(90deg, #182d68 0%, rgba(13, 24, 54, 0) 100%);
      margin: 13px auto 0;
      font-family: Source Han Sans CN;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;

      &_img {
        width: 28px;
        height: 28px;
        border-radius: 5px;
        overflow: hidden;
        margin-left: 10px;
      }

      &_title {
        margin-left: 7px;
      }

      &_r {
        width: 35px;
        height: 35px;
      }
    }

    .mode_line_name {
      width: 300px;
      white-space: nowrap;
      /* 不换行 */
      overflow: hidden;
      /* 超出部分隐藏 */
      text-overflow: ellipsis;
      /* 使用省略号显示 */
    }
  }

  .littleWidth {
    width: 471px;
    height: 734px;
    left: 725px;
  }
}

.table {
  width: 80%;
  margin: 0 auto;
  font-size: 24px;
  border-collapse: collapse;

  th,
  td {
    height: 22px;
    border: 1px solid rgb(206, 199, 199);
  }
}

.new_cunTitle {
  margin: 45px 0px 50px;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 5px;
}

.peoples_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  // justify-content: space-between;
  .people_item {
    width: 33.3%;
    text-align: center;
  }

  .peoples_img {
    width: 450px;
    height: 296px;
    margin: 0 auto;
    display: block;
    // box-shadow: 0px 0px 11px 0px rgba(255, 255, 255, 0.7);
  }

  .item_width {
    width: 250px;
    height: 350px;
  }

  .peoples_name {
    font-size: 20px;
    padding: 15px 0;
  }
}

.party_left {
  writing-mode: tb-rl;
  letter-spacing: 40px;
  font-size: 36px;
  height: 430px;
  text-align: center;
  background: #cd1010;
  border-radius: 20px;
  border: 4px solid #fff;
  padding: 0 5px;
}

.party_right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  &_item {
    width: 180px;
    height: 260px;
    position: relative;
    background-image: url('../assets/img/zubg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 25px 0 0 25px;
    cursor: pointer;

    &_center {
      background: #fff;
      width: 156px;
      height: 160px;
      margin: auto;
      position: absolute;
      left: 50%;
      margin-left: -78px;
      top: 50%;
      margin-top: -74px;
    }
  }

  &_title {
    color: #cd1010;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 15px 0;
    letter-spacing: 5px;
  }

  &_shu {
    color: #131212;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 15spx 0;
    letter-spacing: 5px;
  }
}

.details_box {
  display: flex;
  align-items: flex-start;

  .people_item {
    width: 20.5%;
    text-align: center;
  }

  .peoples_img {
    width: 96%;
    max-height: 430px;
    margin: 0 auto;
    display: block;
  }

  .peoples_name {
    font-size: 22px;
    padding: 15px 0;
  }

  .people_inc {
    margin: 0 auto;
    width: 70%;
    font-size: 20px;
    line-height: 32px;
  }
}
.detailMode {
  z-index: 1000;
}
.dmode_box {
  width: 1073px;
  height: auto;
  margin: 26px auto 0;
  .dmode_cover {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0px 0px;
    img,
    video {
      border-radius: 10px 10px 0px 0px;
    }
  }
  .mode_title {
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    margin: 13px 10px;
  }
  .mode_article {
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin: 13px 10px;
  }
  .dmode_box {
    justify-content: flex-start !important;
    margin-top: 10px;
  }
}
.mode_content::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.mode_content::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #0d53b7;
  -webkit-box-shadow: inset 0 0 5px #0d53b7;
  background: #535353;
}

.mode_content::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: transparent;
}
</style>

<style lang="scss">
.el-table {
  background-color: transparent !important;
}

.el-table th.el-table__cell,
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: transparent !important;
}

.el-table tr {
  background-color: transparent !important;
}

.el-table .cell {
  color: #fff;
}

.el-table th.el-table__cell > .cell {
  color: #fff;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent !important;
  /* color: #f19944; */
  /* 设置文字颜色，可以选择不设置 */
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: transparent !important;
}

.el-table .el-table__body-wrapper::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px !important;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px !important;
}

.el-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px !important;
  box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  -webkit-box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  background: #535353 !important;
}

.el-table .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px !important;
  background: #ededed !important;
}
</style>
